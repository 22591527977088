<script setup>
import { computed, onMounted } from 'vue';
import { useWebStore } from '@/store/web.js';
import NavBarItem from "@/components/NavBarItem.vue";
import HamburgerMenu from "@/components/Hamburger-menu.vue";
import LanguageChooser from "@/components/LanguageChooser.vue";




// Define props using defineProps
const props = defineProps({
  height: String
});

// Store setup
const store = useWebStore();


const sites = computed(() => {
  return store.sites.filter((site) => site.show !== false);
});

// CSS properties passed as style props
const CSSProps = computed(() => ({
  '--height': props.height
}));
</script>

<template>
  <div class="navBar" :style="CSSProps">
    <img class="h-3/5 ml-4 mr-24" src="/poliol_logo.webp" @click="store.navigateToPage(store.sites[0].url)" alt="POLIOL Logo">
    <div style="width: 100%; gap: 40px; height: 100%" class="hidden lg:flex">
      <NuxtLink v-for="site in sites" :key="site.url"
      :to="'/' + store.chosenLang + '/' + site.url"
      class="unstyled-link no-wrap">
        <nav-bar-item :id="'nav_' + site.url" :text="site.text"  :link="site.url"/>
      </NuxtLink>
    </div>
    <hamburger-menu
      class="bigMac lg:hidden"
      :items="store.sites"
    ></hamburger-menu>
    <LanguageChooser v-if="store.screenSize!=='mobile'"class="mr-4 hidden sm:flex lg:mr-10" />
  </div>
</template>

<style scoped lang="scss">
.navBar {
  transition: .6s ease;
  background-color: #F9F9F9;
  position: fixed;
  height: var(--height, 7dvh);
  width: 100vw;
  display: flex;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
              0 6.7px 5.3px rgba(0, 0, 0, 0.048),
              0 12.5px 10px rgba(0, 0, 0, 0.06),
              0 22.3px 17.9px rgba(0, 0, 0, 0.072),
              0 41.8px 33.4px rgba(0, 0, 0, 0.086),
              0 100px 80px rgba(0, 0, 0, 0.12);
}
</style>
